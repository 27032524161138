import { useContext, useState } from "react";
import { Context } from "../componets/context/Context";
import Apicall from "../api/Apicall";

export const useGuardian = () => {

    const { app } = useContext(Context);

    const [dataResGuard, setGuardian] = useState(null);
    const [dataIpServ, setDataIpServ] = useState(null);

    const getGuardian = async (data) => {
        try {
            const result = await Apicall({
                url: `${app.apiCore}/servlet/ExternalView?`
                    + `opc=ExternalView.generarGuardian`
                    + `&guardian=${data}`,
                noAuth: true,
                props: {
                    withCredentials : true                }
            });
            if (result === undefined) {
                throw new Error('La respuesta de la API no fue exitosa');
            }
            if (result.data) {
                setGuardian(result.data.guardian);
                setDataIpServ(result.data.ot);
            }
            result.data = dataResGuard
        } catch (error) {
            throw new Error('Error de Red ', error);
        }
        
    }

    return {
        getGuardian,
        dataResGuard,
        dataIpServ
    }

}